function toggleToast(message, type='success', appendedItem=null) {
    const toastContainer = $('#toast-container');
    const toastClass = 'toast'+(type === 'error' ? ' error' : '');
    const iconClass = type === 'error' ? 'fa-times' : 'fa-check';
    const toast = $('<div />', {
        'class': toastClass,
        'text': message
    }).prepend(`<i class="fas mr-1 ${iconClass}"></i>`);
    toast.append(appendedItem).appendTo(toastContainer);
    const br = $('<br>').appendTo(toastContainer);

    setTimeout(function() {
        toast.remove();
        br.remove();
    }, 6000)

}

export default toggleToast;
